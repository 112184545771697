@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap);
:root {
  --primary-white :#ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-light-grey2: lightgrey;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange : #f9ab00;
  --primary-dark: rgb(52,58,64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-black: #000000;
  --primary-green: green;
  --primary-azulmarino: #141e40;


}

body {
  font-family: "Roboto Condensed", sans-serif !important;
 
}

/******************** NAVBAR ************************/

nav {
  z-index: 2;
  
}

.logo {
  width: 16rem;
  padding-top: 0.3rem;
}

/******************** MENU LINKS ************************/
nav {
  width: 100%;
  height: 70px;
  z-index: 999999;
 
  background:linear-gradient(to bottom,#25211e 0,rgba(37,33,30,0) 100%);
 
}

nav a.nav-link {
  font-size: .9rem;
  letter-spacing: .0375rem;
  cursor:pointer;
}

nav ul li {
  text-transform:uppercase;
}

.navbar-nav > li > a{
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: #ffffff !important;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid var(--primary-violet) !important;
 
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
}

.navbar-nav > li > a.active, .navbar-nav > li > a:focus {
  border-top: 0.15rem solid #f9ab00 !important;
  border-top: 0.15rem solid var(--primary-orange) !important;
}

.navbar-nav > li > a:hover {
  border-top: 0.15rem solid #f9ab00 !important;
  border-top: 0.15rem solid var(--primary-orange) !important;
}

/******************** HEADER ************************/

.header-wraper {
  position: relative;
  background: url(/static/media/back2.8899244f.jpeg) center no-repeat;
  background-size: cover;
  height: 100vh;
}

.header-wraper:before {
	content:'';
	position: absolute;
        top: 0;
	bottom: 0;
	left: 0;
	right: 0;
  background-color: rgba(0,0,0,0.5);
 
}


.main-info {
  display: -webkit-flex;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  text-align: center;
  z-index: 1;
  
}

.main-info p {
  color: #f9ab00 !important;
  color: var(--primary-orange) !important;
  font-size: 2.2rem;
  
}

.main-info  .companyname {
  color: lightgrey !important;
  color: var(--primary-light-grey2) !important;
  font-size: 2.8rem;
  -webkit-animation: anim 2s;
          animation: anim 2s; 
}



.main-info  .companysub {
  -webkit-animation: anim 1.5s .5s backwards;
          animation: anim 1.5s .5s backwards; 
}

@-webkit-keyframes anim {
  0%{
    opacity:0;
  }
  100%{
    opacity:100;
  }
}

@keyframes anim {
  0%{
    opacity:0;
  }
  100%{
    opacity:100;
  }
}

@media(max-width: 768px) {
  .main-info p{
    font-size: 1.7rem !important;
  }

  .typed-text {
    font-size: 1.3rem !important;
  }
}

/********************* TYPED TEXT *******************************/

.typed-text {
  font-size: 1.8rem;
  color: #ffffff;
  color: var(--primary-white);
  
}


/********************* TYPED TEXT *******************************/


.btn-main-offer {
  border: 1px solid #ef4035;
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: #ffffff;
  color: var(--primary-white);
  margin: 2rem;
  -webkit-animation: anim .5s 1.5s backwards;
          animation: anim .5s 1.5s backwards; 
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: #f33501;
  background-color: var(--primary-hover-red);
  color: #ffffff;
  color: var(--primary-white);
  transition: .2s ease-in-out;
}

/********************* PARTICLES *******************************/

.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index:1;
}

@media(max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/********************* ABOUT ME *******************************/

.photo-wrap {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}


.about-us {
  margin-left: 3rem;
  display:-webkit-flex;
  display:flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.about-us-images {
  -webkit-flex: 0 1 50%;
          flex: 0 1 50%;
  position: relative;
  padding: 8rem;
}

.about-us-content {
  -webkit-flex: 1 1 50%;
          flex: 1 1 50%;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 3rem;
  padding-left: 0;
  box-sizing: border-box;
}

.profile-img {
  width: 35%;
  position: absolute;
  top: 60%;
  left:50%;
  box-shadow: 0 3rem 6rem 2px rgba(0,0,0,0.4);
  border-radius: 0rem;
  border : .1rem solid #f9ab00;
  border : .1rem solid var(--primary-orange);
  right: 0;
}

.client-img{
  width: 7rem;
  margin: 1rem;
}

.profile-img1 {
  -webkit-transform: translate(-20%, -90%);
          transform: translate(-20%, -90%);

}

.profile-img2 {
  -webkit-transform: translate(-70%, -70%);
          transform: translate(-70%, -70%);
}

.profile-img3 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.profile-img4 {
  -webkit-transform: translate(-30%, -30%);
          transform: translate(-30%, -30%);
}

.sectext {
  text-align:center;
  font-size: 90%;
  color: #141e40;
  color: var(--primary-azulmarino);
}

.about-heading {
  text-transform: uppercase;
  color: #f9ab00;
  color: var(--primary-orange);
}

.btn-about {
  border: 1px solid #ffffff;
  border: 1px solid var(--primary-white);
  border-radius: 0;
  color: #f9ab00;
  color: var(--primary-orange);
  margin: .5rem;
  background: #f4f4f4;
  background: var(--primary-light-grey);
  cursor: pointer;

}

.btn-about:hover {
  text-decoration: none;
  color: #ef4035;
  color: var(--primary-red);
  transition: .2s ease-in-out;
}

@media(max-width: 468px) {
  .profile-img {
    width: 10rem;
  }
}

/********************* SERVICES *******************************/

.services {
  text-align: center;
  padding-bottom: 2rem;
  background: #141e40;
  background: var(--primary-azulmarino);
}

.services h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  text-transform: uppercase;
}

.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: #f9ab00;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}

.services .icon {
  font-size: 1.4rem;
  color: #ffffff;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.services .box {
  height: 16rem;
  border-bottom: 0.3125rem solid #f9ab00;
  border-bottom: 0.3125rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: #ffffff;
  background: var(--primary-white);
  transition: 0.3s ease-in-out;
}

.services .box:hover {
  background: #f9ab00;
  background: var(--primary-orange);
  border-bottom: 0.3125rem solid rgb(52,58,64);
  border-bottom: 0.3125rem solid var(--primary-dark);
  cursor: pointer;
}

.services .box:hover .icon {
  color: rgb(52,58,64);
  color: var(--primary-dark);
}

.services .box:hover .circle {
  background: #ffffff;
  background: var(--primary-white);
}

.services .box:hover p {
  color: #ffffff;
  color: var(--primary-white);
}

.btn_service {
  background-color: #141e40;
  background-color: var(--primary-azulmarino);
  color: white;
  border: 1px solid #f9ab00;
  border: 1px solid var(--primary-orange);
  font-size: 1rem;
}

.btn_service:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.service-img {
  width: 26rem;
  border-radius: 0%;
  padding : 0rem;
  border: 2px solid #f9ab00;
  border: 2px solid var(--primary-orange);
 
}

/********************* EXPERIENCE *******************************/

.experience {
  padding: 0.7rem;
}

.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}

.experience h1 {
  text-transform: uppercase;
  color: #f9ab00;
  color: var(--primary-orange);
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: #ccd1d9;
  background: var(--primary-grey);
  z-index: 1;
}

.timeline-block {
  width: calc(50% + 0.5rem);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  clear: both;
}

.timeline-block-right{
  float: right;
}

.timeline-block-left{
  float: left;
  direction: rtl;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid rgb(52,58,64);
  border: 0.3125rem solid var(--primary-dark);
  background: #f9ab00;
  background: var(--primary-orange);
  margin-top: 0.625rem;
  z-index: 3;
}

.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: #666666;
  color: var(--primary-dark-grey);
  direction: 1tr;
}

.timeline-content h3 {
  margin-top: 0.3125rem;
  color: #ef4035;
  color: var(--primary-red);
  font-weight: 900;
}

@media(max-width: 768px) {

  .experience .container {
    padding-left: 0;
  }

  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .timeline-block-right {
    float: none;
    width: 100%;
  }
  .timeline-block-left {
    float: none;
    width: 100%;
    direction: ltr;
  }
}


/********************* PORTAFOLIO *******************************/

.portfolio-wrapper {
  background: #f4f4f4;
  background: var(--primary-light-grey);
  padding-bottom: 2rem;
}

.portfolio-wrapper2 {
  background: #141e40;
  background: var(--primary-azulmarino);
  padding-bottom: 2rem;
}

.portfolio-wrapper h1 {
  text-align: center;
  color: #f9ab00;
  color: var(--primary-orange);
  text-transform: uppercase;
}

.portfolio-wrapper2 h1 {
  text-align: center;
  color: #f9ab00;
  color: var(--primary-orange);
  text-transform: uppercase;
}

.portfolio-image {
  width:  15rem;
  border: 1px solid #f9ab00;
  border: 1px solid var(--primary-orange);
  padding:  0.5rem;
}

.portfolio-image-box {
  position: relative;
  margin: 1rem;
}

.overflow {
  position: absolute;
  opacity: 0;
  background: #000000;
  background: var(--primary-black);
  width: 15rem;
  height: 11.5rem;
  top: 0;
  border: 1px solid #ef4035;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}

.portfolio-image-box:hover .overflow {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  margin-left: -8rem;
  margin-top: 5rem;
  color: #f9ab00;
  color: var(--primary-orange);
  font-size: 1.5rem;
}

.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.portfolio-image-popupbox {
  width: 45rem;
  padding: 0 0.5rem;
}

.hyper-link {
  cursor: pointer;
  color: #f9ab00;
  color: var(--primary-orange);
}

.hyper-link:hover {
  color: #ef4035;
  color: var(--primary-red);
}

@media(max-width: 768px) {
  .portfolio-image-popupbox {
    width: 100%;
  }
}

@media(max-height: 570px) {
  .popupbox-wrapper{
    height: 100%;
  }
  .portfolio-image-popupbox {
    width: 50%;
  }
}

@media(max-height: 280px) {
  .popupbox-wrapper{
    height: 90%;
  }
  .portfolio-image-popupbox {
    width: 30%;
  }
}


/******************** CONTACTS ************************/

.contacts {
  background: #141e40;
  background: var(--primary-azulmarino);
  padding: 3rem 0;
}

.contacts h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  padding: 1.3rem 0;
  text-transform: uppercase;
}

.contacts p {
  color: #f4f4f4;
  color: var(--primary-light-grey);
  font-size: 1rem;
}

.contacts input, .contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.123rem solid lightgrey;
  border-bottom: 0.123rem solid var(--primary-light-grey2);
  border-radius: 0;
  color: #f9ab00;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  padding-left: 0 !important;
}

.contacts .container {
  padding: 4rem 0;
}

.contacts input:focus, .contacts textarea:focus {
  background: transparent;
  color: #f9ab00;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid lightgrey;
  border-bottom: 0.125rem solid var(--primary-light-grey2);
}

.contacts textarea {
  height: 12.2rem !important;
}

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}

.line {
  position: relative;
  top: -0.8rem;
  width: 0%;
  height: 0.125rem;
  background: #f9ab00;
  background: var(--primary-orange);
  display: inline-block;
  
}

input:focus+.line, textarea:focus+.line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}

input.form-control {
  margin: -0.3rem 0;
}

input::-webkit-input-placeholder{
  color: lightgrey !important;
  color: var(--primary-light-grey2) !important; 
}

input:-ms-input-placeholder{
  color: lightgrey !important;
  color: var(--primary-light-grey2) !important; 
}

input::placeholder{
  color: lightgrey !important;
  color: var(--primary-light-grey2) !important; 
}

textarea.form-control {
  margin: -0.3rem 0;
}

textarea::-webkit-input-placeholder{
  color: lightgrey !important;
  color: var(--primary-light-grey2) !important; 
}

textarea:-ms-input-placeholder{
  color: lightgrey !important;
  color: var(--primary-light-grey2) !important; 
}

textarea::placeholder{
  color: lightgrey !important;
  color: var(--primary-light-grey2) !important; 
}

.error-message {
  color: #ffffff;
  color: var(--primary-white);
  background: #f33501;
  background: var(--primary-hover-red);
  text-align: left;
}

.success-message {
  font-weight: 900;
  font-size: 1rem;
  color: green;
  color: var(--primary-green);
}

@media(max-width: 768px) {
  .contacts {
    overflow-x: hidden;
  }

  .contacts .container {
    padding: 0 1rem;
  }

  .contacts p {
    padding:0 0.4rem;
  }

}

/******************** FOOTER ************************/

.footer {
  background: #000000;
  background: var(--primary-black);
  color: #666666;
  color: var(--primary-dark-grey);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: #666666;
  color: var(--primary-dark-grey);
}

.footer a:hover {
  text-decoration: none;
  color: #f9ab00;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}
